import {
  Appointment,
  BusinessId,
  Field,
} from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { GetAppointment_getAppointment_extraFields } from 'data-layer/queries/__graphql__/GetAppointment';
import React, { useContext, useEffect, useState } from 'react';
import { ClientContext, urlManager } from 'utils';
import { ExtraFieldFileInput } from './ExtraFieldFileInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Stack } from 'components/shared/Stack';
import styled from '@emotion/styled';
import { breakpointLarge, unit } from 'styles';
import { useTranslation } from 'react-i18next';
import { ITheme } from 'data-layer/types';
import { CircleSpinner } from 'components/CircleSpinner';
import { toast } from 'react-toastify';

type AppointmentExtraFieldsProps = {
  appointmentExtraFields: GetAppointment_getAppointment_extraFields[];
  onSubmitExtraFields: (
    extraFields: GetAppointment_getAppointment_extraFields[]
  ) => Promise<Appointment>;
};

export type ExtraFieldsFormType = {
  extraFields: GetAppointment_getAppointment_extraFields[];
};

export const AppointmentExtraFields: React.FC<AppointmentExtraFieldsProps> = ({
  appointmentExtraFields,
  onSubmitExtraFields,
}: AppointmentExtraFieldsProps) => {
  const { theme } = useContext(ClientContext);
  const { t } = useTranslation();
  const { register, handleSubmit, reset, setValue, watch } = useForm<
    ExtraFieldsFormType
  >({
    defaultValues: {
      extraFields: [...appointmentExtraFields],
    },
  });
  const [extraFields, setExtraFields] = useState<Field[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const onSubmit: SubmitHandler<ExtraFieldsFormType> = (formData) => {
    setIsSaving(true);
    const submitExtraFields = onSubmitExtraFields(
      formData.extraFields
    ).then(() => setIsSaving(false)).catch(()=> setIsSaving(false));

    void toast.promise(
      submitExtraFields,
      {
        success: t('screens.appointments.dataLoadedSuccess'),
      }
    );
  };

  useEffect(() => {
    const businessID = urlManager.getBusinessId();
    const params = {
      business: {
        id: businessID,
      },
    } as BusinessId;
    void MedMeAPI.field
      .getExtraFields(params)
      .then((res: Field[]) => setExtraFields(res));
  }, []);

  const thereIsAvailableForEditing = extraFields.find(
    (field) =>
      field.field.availableInClientCabinet &&
      field.field.availableForEditingInClientCabinet
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ marginTop: unit * 2 }}>
        <Stack>
          {appointmentExtraFields.map((appointmentExtraField, index) => {
            const extraField = extraFields?.find(
              (field) =>
                field.field.id.toString() ===
                appointmentExtraField.fieldID.toString()
            )?.field;

            return (
              extraField &&
              extraField.availableInClientCabinet &&
              ((!extraField.availableForEditingInClientCabinet &&
                appointmentExtraField.value) ||
                extraField.availableForEditingInClientCabinet) && (
                <Stack
                  key={`extraField-${appointmentExtraField.fieldID}`}
                  sx={{
                    [`@media screen and (max-width: ${breakpointLarge}px)`]: {
                      alignItems: 'center',
                    },
                  }}
                >
                  <p>
                    <b>{appointmentExtraField.fieldName}</b>
                  </p>
                  {/* TODO: change it to map(type -> component) when there are a lot of conditions */}
                  {extraField.fieldType === 'FILE' ? (
                    <ExtraFieldFileInput
                      appointmentExtraField={appointmentExtraField}
                      availableForEditing={
                        extraField.availableForEditingInClientCabinet
                      }
                      register={register(`extraFields.${index}` as const)}
                      setExtraField={(file) =>
                        setValue(`extraFields.${index}`, file)
                      }
                      watch={watch(`extraFields.${index}`)}
                    />
                  ) : (
                    <Text>{appointmentExtraField.value}</Text>
                  )}
                </Stack>
              )
            );
          })}
        </Stack>
        {thereIsAvailableForEditing && (
          <Stack direction="row" spacing={4}>
            <ButtonSaveStyle theme={theme} type="submit" disabled={isSaving}>
              {isSaving && <CircleSpinner theme={theme}/>}
              <b>{t('components.confirmation.save')}</b>
            </ButtonSaveStyle>
            <ButtonCancelStyle
              theme={theme}
              type="button"
              onClick={() => reset()}
              disabled={isSaving}
            >
              <b>{t('components.confirmation.cancel')}</b>
            </ButtonCancelStyle>
          </Stack>
        )}
      </Stack>
    </form>
  );
};

const Text = styled('p')({
  margin: 0,
});

const ButtonSaveStyle = styled('button')((props: { theme: ITheme }) => ({
  display: 'flex',
  gap: unit * 2,
  border: 'none',
  padding: `${unit}px ${unit * 3}px`,
  borderRadius: unit,
  backgroundColor: props.theme.mainColor,
  color: props.theme.backgroundColor,
  fontSize: '16px',
}));

const ButtonCancelStyle = styled('button')((props: { theme: ITheme }) => ({
  border: 'none',
  background: 'transparent',
  color: props.theme.textColor,
  fontSize: '16px',
}));
