import styled from '@emotion/styled';
import { DownloadSpinner } from 'components/DownloadSpinner';
import { GetAppointment_getAppointment_extraFields } from 'data-layer/queries/__graphql__/GetAppointment';
import { ITheme } from 'data-layer/types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientContext } from 'utils';
import { getClientDocumentByTokenAndUserId } from 'utils/file';

type DownloadAndViewFileButtonProps = {
  appointmentExtraField: GetAppointment_getAppointment_extraFields;
};

export const DownloadAndViewFileButton: React.FC<DownloadAndViewFileButtonProps> = ({
  appointmentExtraField,
}: DownloadAndViewFileButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { theme, token, user } = useContext(ClientContext);
  const { t } = useTranslation();

  const handleDownload = () => {
    setIsDownloading(true);
    void getClientDocumentByTokenAndUserId(
      token,
      user,
      appointmentExtraField.value ?? '',
      appointmentExtraField.name ?? appointmentExtraField.fieldName
    )
      .then(() => {
        setIsDownloading(false);
      })
      .catch((e) => {
        setIsDownloading(false);
        console.error(e);
      });
  };

  return (
    <FileButtonWithLinkStyles
      theme={theme}
      disabled={isDownloading}
      onClick={handleDownload}
    >
      {isDownloading && <DownloadSpinner theme={theme} />}
      {`${appointmentExtraField.name ?? appointmentExtraField.fieldName} (${t(
        'screens.appointments.download'
      )})`}
    </FileButtonWithLinkStyles>
  );
};

const FileButtonWithLinkStyles = styled('button')(
  (props: { theme: ITheme }) => ({
    display: 'inline-flex',
    gap: 8,
    alignItems: 'end',
    outline: 'none',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: props.theme.mainColor,
    textDecoration: 'underline',
    padding: 0,
    fontWeight: 500,
    fontSize: 16,
  })
);
