import { GetAppointment_getAppointment_extraFields } from 'data-layer/queries/__graphql__/GetAppointment';
import React, { useContext, useState } from 'react';
import { ClientContext } from 'utils';
import styled from '@emotion/styled';
import { ITheme } from 'data-layer/types';
import { breakpointLarge, unit } from 'styles';
import { ReactComponent as IconDelete } from '../../assets/icon-delete.svg';
import { useTranslation } from 'react-i18next';
import { DownloadAndViewFileButton } from './DownloadAndViewFileButton';
import { uploadFile } from 'data-layer/resolvers';
import { IconButton } from 'components/shared/IconButton';
import { Stack } from 'components/shared/Stack';
import { UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { ExtraFieldsFormType } from './AppointmentExtraFields';

type ExtraFieldFileProps = {
  appointmentExtraField: GetAppointment_getAppointment_extraFields;
  register: UseFormRegisterReturn;
  setExtraField: (
    extraField: GetAppointment_getAppointment_extraFields
  ) => void;
  watch: GetAppointment_getAppointment_extraFields;
  availableForEditing?: boolean;
};

export const ExtraFieldFileInput: React.FC<ExtraFieldFileProps> = ({
  appointmentExtraField,
  availableForEditing,
  register,
  setExtraField,
  watch,
}: ExtraFieldFileProps) => {
  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    if (files && files[0]?.name) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      const fileTokenUpload: string = await uploadFile(files[0]);
      if (fileTokenUpload) {
        setExtraField({
          ...appointmentExtraField,
          value: fileTokenUpload,
          name: files[0].name,
        });
      }
    }
  };

  return watch.value ? (
    <Stack spacing={2} direction={'row'} sx={{
      [`@media screen and (max-width: ${breakpointLarge}px)`]: {
        justifyContent: 'center'
      }
    }}>
      <DownloadAndViewFileButton appointmentExtraField={watch} />
      {availableForEditing && <IconButton
        type="button"
        iconSize={2}
        onClick={() =>
          setExtraField({
            ...appointmentExtraField,
            value: null,
            name: null,
          })
        }
      >
        <IconDeleteStyled />
      </IconButton>}
    </Stack>
  ) : (
    <input
      {...register}
      type="file"
      multiple={false}
      onChange={onFileChange}
    />
  );
};

const IconDeleteStyled = styled(IconDelete)((props: { theme: ITheme }) => ({
  stroke: props.theme.mainColor,
  width: (unit - 1) * 2,
  height: (unit - 1) * 2,
}));
