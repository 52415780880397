import { gql } from 'apollo-boost';

export const GET_APPOINTMENT = gql`
  query GetAppointment($ID: ID!) {
    getAppointment(id: $ID) @client {
      id
      businessID
      appointment {
        id
        start
        price {
          amount
          currency
        }
        duration
      }
      resource {
        id
        name
        surname
        middleName
        icon_url
        description
        profession
        degree
        experience
      }
      taxonomy {
        id
        alias
        confirmationAlert
        extraDescription
      }
      client {
        id
        shortId
        phone {
          country_code
          area_code
          number
        }
      }
      telemedData {
        shortJoinUrl
        joinUrl
      }
      extraFields {
        fieldID
        fieldName
        value
        name
      }
      client_appear
    }
  }
`;
