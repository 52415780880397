import { gql } from 'apollo-boost';

export const GET_APPOINTMENTS = gql`
  query GetAppointments(
    $clientID: String!
    $user: String!
    $token: String!
    $businessID: String
    $networkID: String
    $contractID: String
    $contractExtraId: String
  ) {
    getAppointmentHistory(
      clientID: $clientID
      user: $user
      token: $token
      businessID: $businessID
      networkID: $networkID
      contractID: $contractID
      contractExtraId: $contractExtraId
    ) @client {
      appointments {
        id
        businessID
        appointment {
          id
          start
          price {
            amount
            currency
          }
          duration
          status
        }
        resource {
          id
          name
          surname
          middleName
          siteId
          icon_url
          description
          profession
          degree
          experience
        }
        taxonomy {
          id
          alias
          confirmationAlert
          extraDescription
        }
        client {
          id
          shortId
          phone {
            country_code
            area_code
            number
          }
        }
        telemedData {
          shortJoinUrl
          joinUrl
        }
        extraFields {
          fieldID
          fieldName
          value
          name
        }
        client_appear
      }
    }
  }
`;
