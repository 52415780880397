import { gql } from 'apollo-boost';

export const GET_BUSINESS = gql`
  query getBusiness($id: ID!, $contractID: String, $contractExtraId: String) {
    getBusinessByID(id: $id, contractID: $contractID, contractExtraId: $contractExtraId) @client {
      id
      info {
        general_info {
          networkID
          logo_url
          name
          shortName
          address {
            country
            address
            latitude
            longitude
          }
          description
          phone {
            country_code
            area_code
            number
          }
          timezone
          showExtraFieldsInClientCabinet
        }
        backoffice_configuration {
          enableExtendedPhone
          enablePhoneNationalMode
          clientCancellationRestriction {
            active
            disableInHours
          }
          hideNotComeRecordsInClientCabinet
          enableQuickRecordButtonInClientCabinet
          enablePassportIdInClientCabinet
        }
        widget_configuration {
          requireAgreement
          requireAgreementLink
          newWidgetTheme {
            mainColor
            backgroundColor
            itemBackgroundColor
            pastItemBackgroundColor
            telemedItemBackgroundColor
            startRecordBackgroundColor
            passiveColor
            textColor
            backdropColor
            chevronColor
            clientCabinetTranslates
            font {
              name
              medium
              regular
              bold
              light
            }
            patientNoAppearColor
          }
          middleNameSupport
        }
      }
      integrationData {
        ehr {
          active
          protocol
          host
          port
          path
          availableEntities
        }
      }
      resources {
        name
        surname
        middleName
        id
        extraId
        siteId
      }
      taxonomies {
        name
        id
        extraId
        siteId
      }
      top_services {
        status
        services
      }
    }
  }
`;
